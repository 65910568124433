import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: "/tron",
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/okex.vue"),
      },
      {
        path: "/tron",
        name: "tron",
        component: () => import("../views/tron.vue"),
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
